import React, { useState } from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import InstantSearch from "components/search/instant"
import { motion, AnimatePresence } from "framer-motion"

import Layout from "components/layout"

export default () => {
  const location = useLocation()
  const [shown, setShown] = useState(true)

  function goBack() {
    if (window && location.key && location.key !== "initial") {
      navigate(-1)
    } else {
      navigate("/")
    }
  }

  function close() {
    setShown(false)
  }

  return (
    <>
      <div className="m:hidden">
        <Layout openMenu={true} onCollapse={goBack} />
      </div>
      <div className="hidden m:block">
        <div className="bg-black min-h-screen w-screen top-0 left-0 fixed" />
        <AnimatePresence onExitComplete={goBack}>
          {!!shown && (
            <>
              <motion.div
                key="search-bg"
                animate={{ scaleY: 1 }}
                initial={{ scaleY: 0 }}
                exit={{ scaleY: 0 }}
                transition={{ duration: 0.2 }}
                className="absolute top-0 left-0 w-full z-50 bg-white hidden m:block"
                style={{ height: 120, originY: "0px" }}
              ></motion.div>
              <motion.div
                key="search-box"
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1 }}
                className="absolute top-0 w-full m:h-screen m:overflow-y-scroll z-50"
                id="search-box"
              >
                <InstantSearch
                  autofocus={true}
                  location={location}
                  close={close}
                />
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}
